export const BookingErrorImage = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4950_22124)">
        <mask
          id="mask0_4950_22124"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="80"
          height="80"
        >
          <path d="M0 7.62939e-06H80V80H0V7.62939e-06Z" fill="white" />
        </mask>
        <g mask="url(#mask0_4950_22124)">
          <path
            d="M78.4375 26.0005V15.0605C78.4375 13.2371 76.9609 11.7607 75.1375 11.7607H48.4031L63.7063 38.2668C65.3844 41.173 63.2875 44.8045 59.9328 44.8045H20.0688C16.7141 44.8045 14.6172 41.173 16.2953 38.2668L31.5984 11.7607H4.8625C3.04062 11.7607 1.5625 13.2371 1.5625 15.0605V51.9902H78.4375V39.7341"
            stroke="#1D2939"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M75.1375 11.7602C76.9609 11.7602 78.4375 13.2366 78.4375 15.06V61.0086C78.4375 62.832 76.9609 64.3086 75.1375 64.3086H4.8625C3.04062 64.3086 1.5625 62.832 1.5625 61.0086V15.06C1.5625 13.2366 3.04062 11.7602 4.8625 11.7602H31.5984L16.2953 38.2663C14.6172 41.1725 16.7141 44.8039 20.0688 44.8039H59.9328C63.2875 44.8039 65.3844 41.1725 63.7062 38.2663L48.4031 11.7602H75.1375Z"
            stroke="#1D2939"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M46.7259 64.3093H39.9995H33.2731C33.2731 64.3093 33.2414 73.3193 29.4922 78.4355H39.9995H50.5069C46.7577 73.3193 46.7259 64.3093 46.7259 64.3093Z"
            stroke="#1D2939"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25.1172 78.4355H54.8812"
            stroke="#1D2939"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M36.2255 3.74402L16.2934 38.2675C14.6162 41.1725 16.7126 44.8037 20.0672 44.8037H59.9314C63.2859 44.8037 65.3823 41.1725 63.7051 38.2675L43.773 3.74402C42.0958 0.839022 37.9026 0.839022 36.2255 3.74402Z"
            stroke="#FA2846"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40 15.5723V28.9316"
            stroke="#FA2846"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40 36.0002L40 35.918"
            stroke="#FA2846"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35.2617 58.707H44.7417"
            stroke="#1D2939"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4950_22124">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
