'use client';

import { Button, Typography } from '@v2/ui';
import { BookingErrorImage } from '@v2/views/booking-error/containers/components/BookingErrorImage';
import { useEffect } from 'react';

const Error = ({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) => {
  useEffect(() => {
    console.log('Error: ', error);
  }, [error]);

  return (
    <>
      <div className="mx-auto w-full max-w-[720px]">
        <div className="flex flex-col items-center px-4 md:px-0">
          <BookingErrorImage />
          <Typography
            size="dmd"
            className="mb-4 text-center font-bold"
          >{`Whoops, something went wrong.`}</Typography>
          <div className="mb-8 text-center">
            <Typography size="tmd" className="mb-2 font-medium">
              The page you requested encountered an error. Please try refreshing the page or try again later.
            </Typography>
          </div>
          <Button
            variant="secondary"
            onClick={() => {
              reset();
            }}
          >
            Try again
          </Button>
        </div>
      </div>
    </>

  );
};

export default Error;
